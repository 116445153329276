import { Ajax, Apis } from '../../common';
import { AjaxResponse, ModelWidgetInterface, PolicyModel, WidgetHelped } from '../../types';
import Alert from '../../public-component/alert';

export enum ProductFactorLimitation {
    // 保费限额
    PREM_LIMIT = 'PREM_LIMIT',
    // 投保年龄限制
    INS_AGE_LIMIT = 'INS_AGE_LIMIT',
    // 续保年龄限制
    RENEWAL_INS_AGE_LIMIT = 'RENEWAL_INS_AGE_LIMIT',
    // 投保人年龄限制
    PH_AGE_LIMIT = 'PH_AGE_LIMIT',
    // 保额限制
    SA_LIMIT = 'SA_LIMIT',
    // 份数限制
    UNIT_LIMIT = 'UNIT_LIMIT',
    // 允许投保职业类别限制
    JOB_CLASS_ALLOWED = 'JOB_CLASS_ALLOWED',
    // 不允许投保职业类别限制
    JOB_CLASS_DISALLOWED = 'JOB_CLASS_DISALLOWED',
    // 允许投保职业限制
    JOB_CATE_ALLOWED = 'JOB_CATE_ALLOWED',
    // 不允许投保职业限制
    JOB_CATE_DISALLOWED = 'JOB_CATE_DISALLOWED',
    // 被保人证件类型限制
    INS_ID_TYPE_ALLOWED = 'INS_ID_TYPE_ALLOWED'
}

export type ProductApplyFactor = {
    CALC_UNIT?: string[],
    PAYMENT_FREQ?: string[],
    PH_JOB_CATE_CODE: string[],
    PREM?: string[],
    SA?: string[],
    PH_DATE_OF_BIRTH?: string[],
    DATE_OF_BIRTH?: string[],
    JOB_CATE_CODE?: string[],
    PH_ENTRY_AGE?: string[],
    COVERAGE_PERIOD?: string[],
    BENEFIT_LEVEL?: string[],
    PH_GENDER?: string[],
    ENTRY_AGE?: string[],
    PH_FULL_NAME?: string[],
    GENDER?: string[],
    CHARGE_PERIOD?: string[],
    FULL_NAME?: string[],
    CV_UNIT?: string[],
    RELATION_WITH_PH?: string[],
    INCREASE_STEP_LIMIT?: string[],
    // 自动垫交标识
    AUTO_LOAN?: string[],
    // 自动续保标识
    AUTO_RENEWAL_INDI?: string[],
    // 保证续保标识
    FLAG_GUARANTEE_RENEWAL?: string[],
    /** 年金领取年龄 */
    ANN_PAY_PERIOD?: string[];
    /** 年金领取频率 */
    ANN_PAY_FREQ?: string[];
    /** 生存金领取方式 */
    SB_PAY_MODE?: string[];
    /** 被保险人是否有社保 */
    SOCIAL_INSURANCE_INDI?: string[];
    /** 被保险人职业类别 */
    JOB_CLASS?: string[];
    /** 标体类型 */
    STANDARD_LIFE_TYPE?: string[];
    /** 代理人机构 */
    AGENT_ORGAN_CODE?: string[];
    /** 份数 */
    UNIT?: string[];
    /** 关联万能账户 */
    RELATED_TIV_ACCOUNT?: string[];
    /** 红利领取方式 */
    CB_PAY_MODE?: string[];
    /** 免赔额 */
    DEDUCTIBLE?: string[];
    /** 年金保证领取年限 */
    ENSURE_PAY?: string[];
    /** 年金领取方式 */
    ANN_PAY_MODE?: string[];
    /** 年金延期年期 */
    ANN_END_PERIOD?: string[];
    /** 是否新单 */
    IS_NEW?: string[];
    /** 是否自保件 */
    SELF_INSURANCE?: string[];
    /** 受益人出生日期 */
    BNF_DATE_OF_BIRTH?: string[];
    /** 受益人年龄 */
    BNF_ENTRY_AGE?: string[];
    /** 受益人是否有社保 */
    BNF_SOCIAL_INSURANCE_INDI?: string[];
    /** 受益人性别 */
    BNF_GENDER?: string[];
    /** 受益人姓名 */
    BNF_FULL_NAME?: string[];
    /** 受益人职业编码 */
    BNF_JOB_CATE_CODE?: string[];
    /** 受益人职业类别 */
    BNF_JOB_CLASS?: string[];
    /** 投保地区 */
    SALES_AREA?: string[];
    /** 投保人是否有社保 */
    PH_SOCIAL_INSURANCE_INDI?: string[];
    /** 投保人职业类别 */
    PH_JOB_CLASS?: string[];
    /** 与被保人关系 */
    RELATION_WITH_INSURED?: string[];
    /** 与主被保人关系 */
    RELATION_WITH_MAIN_INSURED?: string[];
    /** 祝寿金领取年龄 */
    BIRTH_PAY_AGE?: string[];
    /** 支持续保 */
    FLAG_APL?: string[];
    /** 支持保证续保 */
    FLAG_AUTO_RENEWAL?: string[];
    /** 万能账户自动抵交保费 */
    PREMIUM_REDUCTION_OPTION?: string[];
    /** 退休年龄 */
    RETIRE_AGE?: string[];
}

export type ProductApplyLimitation = {
    limitationType: string,
    factorType: string,
    // 出参范围
    limitationValue: {
        range: boolean,
        values: string[]
    }
    // 入参因子
    factorValue?: {
        range: boolean,
        values: string[]
    }
}

export enum FactorType {
    GENDER = "GENDER",
    COVERAGE_PERIOD = "COVERAGE_PERIOD",
    CHARGE_PERIOD = "CHARGE_PERIOD",
    ANN_PAY_PERIOD = "ANN_PAY_PERIOD",
    RETIRE_AGE = "RETIRE_AGE",
    BENEFIT_LEVEL = "BENEFIT_LEVEL",
    ANN_PAY_FREQ = "ANN_PAY_FREQ",
}

/** 原有投保限制因子 */
export type ProductApplyLimitationItem = {
    factorTypes: FactorType[],
    limitationFactors: {
        factorValues: {
            range: boolean;
            values: string[];
        }[]
        limitationValue: {
            range: boolean;
            values: string[];
        }
    }[],
    limitationType: string;
}

// 可选责任
export type OptionalLiabilities = {
    liabilityCode: string,
    liabilityName: string,
    displayOrder: number,
    mustSelect: boolean
}

// 页面驱动模型
export type ProductDriveFactor = {
    productCode: string;
    productName: string;
    factors: ProductApplyFactor
    limitations: ProductApplyLimitation[],
    _limitations: ProductApplyLimitationItem[],
    attachProducts: [
        {
            productCode: string,
            productName: string,
            factors: ProductApplyFactor,
            limitations: ProductApplyLimitation[],
            optionalLiabilities?: OptionalLiabilities[]
        }
    ],
    relations: [],
    optionalLiabilities?: OptionalLiabilities[]
}

const analyzeFactors = (data: any): ProductDriveFactor => {
    let analyzedFactors = {} as ProductDriveFactor;
    analyzedFactors.productCode = data.productCode;
    analyzedFactors.productName = data.productName;
    // 投保因子
    analyzedFactors.factors = data.factors;
    // 可选责任(可空)
    analyzedFactors.optionalLiabilities = data.optionalLiabilities || [];
    // 投保限制因子
    let productApplyLimitation = [] as ProductApplyLimitation[];
    (data.limitations || []).forEach((limit: any) => {
        (limit.limitationFactors || []).forEach((limitFactor: any) => {

            let limitationValue = {} as any;
            limitationValue.range = limitFactor.limitationValue.range;
            limitationValue.values = limitFactor.limitationValue.values;

            if (limitFactor.factorValues && limitFactor.factorValues.length > 0) {
                limitFactor.factorValues.forEach((factor, index) => {
                    let factorValue = {} as any;
                    factorValue.range = factor.range;
                    factorValue.values = factor.values;
                    productApplyLimitation.push({
                        factorType: (limit.factorTypes && limit.factorTypes.length > 0) ? limit.factorTypes[index] : '',
                        limitationType: limit.limitationType,
                        limitationValue: limitationValue,
                        factorValue: factorValue
                    });
                })
            } else {
                productApplyLimitation.push({
                    limitationType: limit.limitationType,
                    limitationValue: limitationValue,
                    factorType: ''
                });
            }
        })
    })
    analyzedFactors.limitations = productApplyLimitation;

    // 原有投保限制因子
    analyzedFactors._limitations = data.limitations;

    let attachProducts = [] as any;
    // 搭配险种限制
    (data.attachProducts || []).forEach((attachProduct: any) => {
        let limitations = [] as ProductApplyLimitation[];
        (attachProduct.limitations || []).forEach((limit: any) => {
            (limit.limitationFactors || []).forEach((limitFactor: any) => {

                let limitationValue = {} as any;
                limitationValue.range = limitFactor.limitationValue.range;
                limitationValue.values = limitFactor.limitationValue.values;

                limitations.push({
                    factorType: (limit.factorTypes && limit.factorTypes.length > 0) ? limit.factorTypes[0] : '',
                    limitationType: limit.limitationType,
                    limitationValue: limitationValue
                })
            })
        })
        
        attachProducts.push({
            productCode: attachProduct.productCode,
            productName: attachProduct.productName,
            factors: attachProduct.factor,
            limitations: limitations
        });
    });
    analyzedFactors.attachProducts = attachProducts;
    return analyzedFactors;
}

const FactorTypeValueInModelMap = new Map([
    [FactorType.GENDER, 'gender'],
    [FactorType.COVERAGE_PERIOD, 'extensionData.coveragePeriod'],
    [FactorType.CHARGE_PERIOD, 'extensionData.installment'],
    [FactorType.ANN_PAY_PERIOD, 'extensionData.payPeriod'],
    [FactorType.RETIRE_AGE, 'extensionData.retireAge'],
]);

const LimitationTypeValueInModelMap = new Map([
    [ProductFactorLimitation.INS_AGE_LIMIT, 'extensionData.age'],
    [ProductFactorLimitation.PREM_LIMIT, 'extensionData.sumInsured'],
]);


/**
 * 页面驱动因子
 */
class UIDriveEngine {

    static productDriveFactor: ProductDriveFactor;

    static loadProductProfile = async (productCode: string): Promise<ProductDriveFactor> => {
        return await new Promise((resolve, reject) => {
            Ajax.get(Apis.BASEINFO_UI_DRIVE_PARAMS
                .replace('{productCode}', productCode))
                .then((response: AjaxResponse) => {
                    const data = (response.body || {}).body || {};
                    if (data) {
                        let analyzedFactors = analyzeFactors(data);
                        UIDriveEngine.productDriveFactor = analyzedFactors;
                        resolve(analyzedFactors);
                    } else {
                        console.error('没有数据');
                        reject(response);
                    }
                })
                .catch((reason: any) => {
                    console.error('BASEINFO_UI_DRIVE_PARAMS ERROR: ', reason);
                    reject(reason);
                });
        });
    };

    static loadProductProfileWithPolicy = async (
        invoker: WidgetHelped & ModelWidgetInterface,
	    options: { showMsg?: true; hideMsg?: true } = {}
    ): Promise<ProductDriveFactor> => {
        return await new Promise((resolve, reject) => {
            const policy = invoker.getRootModel();
            Alert.ing('提交数据中...');
            Ajax.post(Apis.BASEINFO_UI_DRIVE_PARAMS
                .replace('{productCode}', policy.productCode), policy)
                .then((response: AjaxResponse) => {
                    const data = (response.body || {}).body || {};
                    if (data) {
                        let analyzedFactors = analyzeFactors(data);
                        UIDriveEngine.productDriveFactor = analyzedFactors;
                        Alert.hide();
                        resolve(analyzedFactors);
                    } else {
                        console.error('没有数据');
                        Alert.error('没有数据');
                        reject(response);
                    }
                })
                .catch((reason: any) => {
                    console.error('BASEINFO_UI_DRIVE_PARAMS ERROR: ', reason);
                    Alert.error(`BASEINFO_UI_DRIVE_PARAMS ERROR: ${reason}`);
                    reject(reason);
                });
        });
    };

    static getProductDriveFactor = async (productCode: string) => {
        const productDriveFactor = UIDriveEngine.productDriveFactor;
        if (productDriveFactor) {
            return productDriveFactor;
        } else {
            return await UIDriveEngine.loadProductProfile(productCode);
        }
    }

    static getFactorTypeValueInModel = (factorType: FactorType, policy: PolicyModel) => {
        let value = '';

        switch(factorType) {
            // insured factor
            case FactorType.GENDER:
                value = policy[`insureds[!extensionData.mainInsured=Y].participants[!customerType=2].${FactorTypeValueInModelMap.get(factorType)}`];
                break;
            // coverage factor
            case FactorType.COVERAGE_PERIOD:
            case FactorType.CHARGE_PERIOD:
            case FactorType.ANN_PAY_PERIOD:
            case FactorType.RETIRE_AGE:
                value = policy[`insureds[!extensionData.mainInsured=Y].coverages[!coverageCode=${UIDriveEngine.productDriveFactor.productCode}].${FactorTypeValueInModelMap.get(factorType)}`];
                break;
            default:
                break;
        }

        return value;
    }

    static getLimitationTypeValueInModel = (limitType: ProductFactorLimitation, policy: PolicyModel) => {
        let value = '';

        switch(limitType) {
            // insured factor
            case ProductFactorLimitation.INS_AGE_LIMIT:
                value = policy[`insureds[!extensionData.mainInsured=Y].participants[!customerType=2].${LimitationTypeValueInModelMap.get(limitType)}`];
                break;
            // coverage factor
            case ProductFactorLimitation.PREM_LIMIT:
                value = policy[`insureds[!extensionData.mainInsured=Y].coverages[!coverageCode=${UIDriveEngine.productDriveFactor.productCode}].${LimitationTypeValueInModelMap.get(limitType)}`];
                break;
            default:
                break;
        }

        return value;
    }
}

export { UIDriveEngine }
